// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-business-page-js": () => import("./../src/templates/business-page.js" /* webpackChunkName: "component---src-templates-business-page-js" */),
  "component---src-templates-text-page-js": () => import("./../src/templates/text-page.js" /* webpackChunkName: "component---src-templates-text-page-js" */),
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-about-js": () => import("./../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-partnerships-page-js": () => import("./../src/templates/partnerships-page.js" /* webpackChunkName: "component---src-templates-partnerships-page-js" */),
  "component---src-templates-partnerships-item-page-js": () => import("./../src/templates/partnerships-item-page.js" /* webpackChunkName: "component---src-templates-partnerships-item-page-js" */),
  "component---src-templates-404-js": () => import("./../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */)
}

